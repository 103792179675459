<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Billing
        </h2>
        <div class="card">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 col-md-3">
                        <div  class="form-group mr-1">
                            <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                                <h5>Client</h5>
                                <div class="wrap">
                                    <select
                                        v-model="filters.client"
                                        class="form-control text-capitalize required"
                                        required
                                    >
                                        <option value="" selected>-- Any Client --</option>
                                        <option
                                            v-for="item in clients"
                                            :key="item.value"
                                            :value="item.value"
                                        >
                                            {{ item.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3">
                        <div  class="form-group mr-1">
                            <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                                <h5>Product</h5>
                                <div class="wrap">
                                    <select
                                        v-model="filters.product_type"
                                        class="form-control text-capitalize required"
                                    >
                                        <option value="">-- Product --</option>
                                        <option
                                            v-for="item in products"
                                            :key="item.key"
                                            :value="item.key"
                                        >
                                            {{ item.value }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3">
                        <div  class="form-group mr-1">
                            <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                                <h5>Start Date</h5>
                                <div class="wrap">
                                    <ejs-datetimepicker cssClass="e-outline" id='startPicker' placeholder="start"
                                                        :value="formattedStart"
                                                        @change="onDateChange" >
                                    </ejs-datetimepicker>
                                </div>
                            </div>
                        </div>
                    </div>
<!--297530-->
                    <div class="col-lg-2 col-md-3">
                        <div  class="form-group mr-1">
                            <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                                <h5>End Date</h5>
                                <div class="wrap">
                                    <ejs-datetimepicker cssClass="e-outline" id='endPicker' placeholder="start" :value="formattedEnd" @change="onEndDateChange"  ></ejs-datetimepicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3">
                        <div  class="form-group mr-1">
                            <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                                <h5>Show Hour</h5>
                                <div class="wrap">
                                    <select
                                        v-model="filters.hourly"
                                        class="form-control text-capitalize required"
                                    >
                                        <option :value="true">-- Yes --</option>
                                        <option :value="false">-- No --</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3 row">
                        <div class="tabs-wrap col-lg-2 col-md-3" style="padding-top: 65px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="search">
                                <span class="e-btn-content">SEARCH</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="loading" class="loader-body">
            <div class="loader"></div>
        </div>

        <ejs-grid ref="grid" :dataSource="data" :allowPaging="true"
                  :allowSorting="true" :pageSettings="pageSettings" :toolbar='toolbar'
                  :allowTextWrap='true' :textWrapSettings='wrapSettings'
                  :toolbarClick="toolbarClick" :actionFailure='actionFailure' :actionComplete="actionComplete">
            <e-columns v-if="product_type === 'emailverifier'" >
                <e-column field="date" headerText="Date" text-Align="Left" width="260"></e-column>
                <e-column field="client" headerText="Client" text-Align="Left" width="260"></e-column>
                <e-column v-if="hourly" field="hour" headerText="Hour" text-Align="Left" width="260"></e-column>
                <e-column field="client" headerText="Client" text-Align="Left" width="160"></e-column>
                <e-column field="total" headerText="Total" text-Align="Left" width="160"></e-column>
                <e-column field="fold_top" headerText="Above the Fold" text-Align="Left" width="160"></e-column>
                <e-column field="fold_top_percent" headerText="% Above the Fold" text-Align="Left" width="160"></e-column>
                <e-column field="fold_below" headerText="Below the Fold" text-Align="Left" width="240"></e-column>
                <e-column field="fold_below_percent" headerText="% Below the Fold" text-Align="Left" width="240"></e-column>
            </e-columns>
            <e-columns v-else>
                <e-column field="date" headerText="Date" text-Align="Left" width="260"></e-column>
                <e-column field="client" headerText="Client" text-Align="Left" width="260"></e-column>
                <e-column v-if="hourly" field="hour" headerText="Hour" text-Align="Left" width="260"></e-column>
                <e-column field="total" headerText="Total" text-Align="Left" width="160"></e-column>
                <e-column field="shown" headerText="Shown" text-Align="Left" width="160"></e-column>
                <e-column field="shown_percent" headerText="Shown %" text-Align="Left" width="160"></e-column>
                <e-column field="capped" headerText="Capped" text-Align="Left" width="240"></e-column>
                <e-column field="capped_percent" headerText="Capped %" text-Align="Left" width="240"></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>

<script>
import Vue from "vue";
import moment from "moment-timezone";
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";
//import swal from "sweetalert";
import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Filter, Group,Toolbar} from '@syncfusion/ej2-vue-grids';

import { DataManager, WebApiAdaptor } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
import { DialogPlugin } from '@syncfusion/ej2-vue-popups';
import swal from "sweetalert";
import axios from "axios";

Vue.use(GridPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DialogPlugin);


export default {
    name: "Billing",
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            internalChange: false,
            loading: false,
            data: null,
            clients: [],
            hourly: false,
            product_type: 'emailverifier',
            timezone: moment().tz(this.user.timezone),
            filters: {
                start: moment().startOf("month").toDate(),
                end: moment().endOf('month').toDate(),
                product_type: 'emailverifier',
                client: '',
                hourly: false
            },
            products: [
                { key: "adverifier", value: "Ad Verifier" },
                { key: "emailverifier", value: "Email Verifier" },
            ],
            wrapSettings: { wrapMode: 'Content' },
            toolbar: [
                'Update',
                'Cancel'
            ],
            pageSettings: {
                pageSize: 25,
                pageCount: 10,
                pageSizes: [25, 50, 100, 250, 500, 1000]
            },
        };
    },
    mounted() {
        const user_tz = this.user.timezone ?? 'EST';
        this.filters.start = moment().tz(user_tz).startOf("month").toDate();
        this.filters.end = moment().tz(user_tz).endOf('month').toDate();
    },
    computed: {
        formattedStart() {
            if (this.filters.start) {
                const user_tz = this.user.timezone ?? 'EST';
                console.log(user_tz)
                return moment(this.filters.start).tz(user_tz).format("YYYY-MM-DDTHH:mm:ss");
            }
            return null
        },
        formattedEnd() {
            if (this.filters.end) {
                const user_tz = this.user.timezone ?? 'EST';
                console.log(user_tz)
                return moment(this.filters.end).tz(user_tz).format("YYYY-MM-DDTHH:mm:ss");
            }
            return null
        }
    },
    created: function () {
        this.$root.preloader = false;
        this.getClients()
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        onDateChange(args) {
            console.log(args)
            if(!this.internalChange){
                this.internalChange = true;
                let selectedDate = moment(args.value).format('YYYY-MM-DDTHH:mm:ss');
                this.filters.start = moment.tz(selectedDate, this.user.timezone).format();

                console.log(this.filters.start, this.user.timezone)
                setTimeout(() => {
                    this.internalChange = false;
                }, 1000);
            }
        },
        onEndDateChange(args) {
            console.log(args)
            if(!this.internalChange){
                this.internalChange = true;
                let selectedDate = moment(args.value).format('YYYY-MM-DDTHH:mm:ss');
                this.filters.end = moment.tz(selectedDate, this.user.timezone).format();
                console.log(this.filters.end, this.user.timezone)
                setTimeout(() => {
                    this.internalChange = false;
                }, 1000);
            }
        },
        async getClients() {
            let resp = await axios.get(
                `${this.$root.serverUrl}/admin/dropdown/client`
            );

            if (resp && resp.data && resp.data.error) {
                console.log("");
            } else {
                this.clients = resp && resp.data ? resp.data.data : this.client;
            }
        },
        actionComplete: function(args) {
            console.log(args)
            this.product_type = this.filters.product_type
        },
        actionFailure(args) {
            console.log(args)
            if (args.error.error.status === 401) {
                localStorage.clear();
                document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                if(this.$route.path != "/login")
                    this.$router.push('/login')
            }
        },
        search: async function () {
            console.log(this.user.timezone)
            console.log(this.filters)
            this.searching = true;
            let request = {
                sSearch: null,
                filters: {}
            };
            if (this.filters.start) request.filters.start = this.filters.start;
            if (this.filters.end) request.filters.end = this.filters.end;
            if (this.filters.type) request.filters.product_type = this.filters.type;
            if (this.filters.client) request.filters.client_id = this.filters.client;
            if (this.filters.product_type) request.filters.product_type = this.filters.product_type;
            if (this.filters.hourly) request.filters.hourly = this.filters.hourly;
            this.data = null;
            this.hourly = this.filters.hourly;

            request.page = this.page;
            request.max = this.max;

            let query = [];
            for (var key in request) {
                if (request.hasOwnProperty(key)) {
                    if (request[key]) {
                        query.push(`${key}=${typeof request[key] === 'object' ? JSON.stringify(request[key]) : request[key]}`);
                    }
                }
            }

            console.log(query.join("&"))

            // this.loading = true;?

            this.$nextTick(async () => {
                try {
                    this.data = await new DataManager({
                        url: `${this.$root.serverUrl}/admin/billing?${query.join("&")}`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                    })
                } catch (err) {
                    swal({ title: "Oops", text: err.message, icon: "error" });
                }
            })
        },
        filter(){
            this.$nextTick(() => {
                if (!this.data) {
                    this.data = new DataManager({
                        url: `${this.$root.serverUrl}/admin/handlers/requests/links`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers: [{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }],
                    })
                }
                let filters = Object.assign({}, this.filters);
                if (this.domain) filters.domain = this.domain;
                this.temp = filters;
            })
        },
        toolbarClick: function(args = ClickEventArgs) {
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    swal("Notice!", "Please wait while file is being generated", "warning");
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    swal("Notice!", "Please wait while file is being generated", "warning");
                    break;
                /* tslint:enable */
                case 'CSV Export':
                    this.$refs.grid.csvExport(this.getCsvExportProperties());
                    swal("Notice!", "Please wait while file is being generated", "warning");
                    break;
            }
        },
        exportComplete() {
            swal("Notice!", "File exportation completed", "success");
        },
        getExcelExportProperties: function() {
            return {
                fileName: "links.xlsx",
                enableFilter: true,
            };
        },
        getPdfExportProperties: function() {
            return {
                fileName: "links.pdf"
            };
        },
        getCsvExportProperties: function() {
            return {
                fileName: "links.csv"
            };
        },
        windowListener: function (e) {
            if (e.keyCode === 13 && !this.processing) {
                // this.load();
            }
        }
    },
    provide: {
        grid: [Page, Sort, Filter,Group,Toolbar,PdfExport,ExcelExport]
    },
};
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
